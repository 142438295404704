$primary: #332532; /* MAIN COLOR */
$secondary: #DAEDE2; /* SECONDARY COLOR */
$third: #F77A52;
$fourth: #FF974F;
$fifth: #A49A87;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Droid+Serif|Old+Standard+TT:400,400i');

.flash {
	display:none;
}

h1,h2,h3 {
	font-family: 'Old Standard TT', serif;
	color: $primary;
}
p {
	font-family: 'Droid Serif', serif;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 30px;
		    display: inline-block;
		}

	    &:hover {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	    &:focus {
	    	color: #777;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}

.banner {
	.overlay {
		background: rgba($primary, 0.5);
	}
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	height: 700px;
	background-blend-mode: soft-light;
	position: relative;
	background-position: 50% 50%;
	overflow: hidden;
	@media (max-width: 767px) {
		height: 500px;
	}
		h1 {
			color: rgba($primary,0.8);
			font-size: 30em;
			font-size: 40vw;
			display: inline-block;
			position: absolute;
			top: 54%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			mix-blend-mode: screen;
			@media (max-width: 767px) {
				display: none;
			}
		}
		.bannerInfo {
			display: inline-block;
			position: absolute;
			top: 46%;
			left: 50%;
			width: 75%;
			transform: translate(-50%, -46%);
			h2 {
			color: white;
			font-size: 3em;
			text-align: center;

			}
		}
}
.box {
	position: relative;
	.infoBox1 {
		position: absolute;
		background: white;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		left: 80%;
		padding: 50px;
		@media (max-width: 767px) {
			padding: 25px;
		}
		h1 {
			color: $primary;
			@media (max-width: 767px) {
				font-size: 2em;
			}
		}
		hr {
			width: 20%;
			border-color: #878787;
		}
		@media (max-width: 767px) {
			position: static;
			transform: none;
			background: rgba(255,255,255,0.5);
		}
	}
	.infoBox2 {
		position: absolute;
		background: white;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		right: 80%;
		padding: 50px;
		@media (max-width: 767px) {
			padding: 25px;
		}
		h1 {
			color: $primary;
			@media (max-width: 767px) {
				text-align: center;
			}
		}
		hr {
			width: 20%;
			border-color: #878787;
		}
		@media (max-width: 767px) {
			position: static;
			background: rgba(255,255,255,0.5);
			transform: none;
		}
	}
}
.box1 {
	background: linear-gradient(
       rgba($secondary,0.7),
       rgba($secondary,0.7)
		),url(../img/couple1.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	position: relative;
	@media (max-width: 991px) {
		background-position: 50% 50%;
	}
	@media (max-width: 767px) {
		height: auto;
		padding: 200px 15px;
	 }
	 @media (max-width: 600px) {
	 	padding: 100px 15px;
	 }

}
.box2 {
	background: linear-gradient(
       rgba($third,0.8),
       rgba($third,0.8)
		),url(../img/couple2.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	@media (max-width: 991px) {
		background-position: 50% 50%;
	}
	@media (max-width: 767px) {
		height: auto;
		padding: 200px 15px;
	 }
	 @media (max-width: 600px) {
	 	padding: 100px 15px;
	 }

}
.box3 {
	background: linear-gradient(
       rgba($fourth,0.8),
       rgba($fourth,0.8)
		),url(../img/couple3.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	position: relative;
	@media (max-width: 767px) {
		height: auto;
		padding: 175px 15px;
	}
	@media (max-width: 650px) {
		background-position: 50% 50%;
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		padding: 0;
		margin: 0;
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}
}
.box4 {
	background: linear-gradient(
       rgba($fifth,0.8),
       rgba($fifth,0.8)
		),url(../img/couple4.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	@media (max-width: 767px) {
		height: auto;
		padding: 175px 15px;
	}
	@media (max-width: 650px) {
		background-position: 50% 50%;
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 0;
		margin: 0;
		color: white;
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}
}
.bg {
	background: $primary;
}

.padTopBtm {
	padding: 75px 15px;
}

a.btn {
	@include btn1;
	padding: 20px 50px;
	border-radius: 5px;
	font-family: 'Old Standard TT', serif;
	font-size: 1.2em;
	margin-top: 10px;
	&:focus {
		color: white;
		background: $primary;
	}
}

span[class*="icon"] {
	font-size: 4em;
}

.bg2 {
	background: $secondary;
}

.icon {
	h1 {
		font-size: 2em;
	}
	@media (max-width: 575px) {
		&.col-xs-3 {
			width: 50%;
		}
	}
	@media (max-width: 320px) {
		&.col-xs-3 {
			width: 100%;
		}
	}
}
.clearfix.visible-407 {
	display: none;
 	@media (max-width: 407px) {
 		display: block;
 	}
}
.logo {
	max-width: 225px;
	width: 100%;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}

footer a:hover, footer a:focus {
	outline: 0;
	text-decoration: none;
	color: white;
}